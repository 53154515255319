.container-main{
  background-color: rgb(250, 250, 250);
  height: 100vh;
}

.box{
  background-color: rgba(8, 8, 8, 0.811);
  color: white;
  font-size: larger;
  font-weight: bolder;
  border-radius: 10px;
  height: 300px;
  opacity: 800%;
  box-shadow: 10px rgb(2, 2, 2);
}

.weather-icon{
  height: 150px;
  width: 150px;
}

.cityName{
  font-size: 40px;
}

.temperature{
  font-size: 30px;
}